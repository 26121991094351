/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { Link } from 'react-router-dom';


const MobileAPPServiceList = [
    {
        icon: <FiCast />,
        title: 'React Native',
        description: 'Harness the power of React Native for cross-platform mobile development, ensuring seamless performance across devices.'
    },
    {
        icon: <FiLayers />,
        title: 'Vue Native',
        description: 'Leverage Vue.js for native mobile app development, combining the simplicity of Vue with the efficiency of native applications.'
    },
    {
        icon: <FiUsers />,
        title: 'Flutter',
        description: "Craft beautiful, high-performance applications with Flutter, Google's UI toolkit for building natively compiled applications for mobile, web, and desktop."
    },
    {
        icon: <FiMonitor />,
        title: 'Android',
        description: "Develop robust, feature-rich Android applications tailored to your specifications, reaching millions of users on the world's most popular mobile platform."  },
    {
        icon: <FiCast />,
        title: 'IOS',
        description: "Create elegant, intuitive iOS applications that captivate users and elevate your brand's presence on Apple's renowned platform."
    },
    {
        icon: <FiMonitor />,
        title: 'Hybrid',
        description: 'Optimize development time and costs with hybrid app solutions, leveraging frameworks like Ionic and PhoneGap to deliver cross-platform experiences seamlessly.'
    },
]

const WebAPPServiceList = [
    {
        icon: <FiCast />,
        title: 'Laravel',
        description: 'Build scalable, secure web applications with Laravel, the PHP framework known for its elegant syntax and robust features.'
    },
    {
        icon: <FiLayers />,
        title: 'Symfony',
        description: "Harness Symfony's flexibility and scalability to create custom web solutions tailored to your business requirements."
    },
    {
        icon: <FiUsers />,
        title: 'CodeIgniter',
        description: 'Develop dynamic, lightweight web applications efficiently with CodeIgniter, the PHP framework prized for its speed and simplicity.'
    },
    {
        icon: <FiMonitor />,
        title: 'Wordpress',
        description: 'Unlock the full potential of WordPress with custom themes and plugins, empowering your website with flexibility, scalability, and ease of use.'
    },
    {
        icon: <FiCast />,
        title: 'Node JS',
        description: "Node.js revolutionizes backend development with its event-driven architecture, enabling fast, scalable, and efficient server-side JavaScript applications."
    },
    {
        icon: <FiMonitor />,
        title: 'Python',
        description: 'Python, a versatile programming language, excels in simplicity and readability, making it ideal for web development, data analysis, and automation tasks.'
    },
]

const DigitalMarketingServiceList = [
    {
        icon: <FiCast />,
        title: 'SEO Marketing',
        description: 'Improve your online visibility and drive organic traffic with our strategic SEO marketing services, optimizing your website for higher search engine rankings and increased exposure.'
    },
    {
        icon: <FiLayers />,
        title: 'SMO Marketing',
        description: "Strengthen your brand's presence on social media platforms with our SMO marketing services, engaging your audience and building meaningful connections to enhance brand loyalty and drive results."
    },
    {
        icon: <FiUsers />,
        title: 'PPC',
        description: "Maximize your ROI with targeted pay-per-click advertising campaigns, reaching the right audience at the right time to drive conversions and grow your business."
    }
]
class Service extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Service' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Service'}   />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Mobile Application Development</h2>
                                    <p>For mobile web development, leverage responsive design and progressive web app technologies for optimal user experience across devices</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {MobileAPPServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <Link to={`/service-details/${val.title.toLowerCase().replace(/\s+/g, '-')}`}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                <img src={`assets/icons/${val.title.toLowerCase().replace(/\s+/g, '-')}.svg`} ></img>
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Website Development</h2>
                                    <p>In web development, harness the latest technologies and frameworks to create dynamic, user-centric websites tailored to your business needs.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {WebAPPServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <Link to={`/service-details/${val.title.toLowerCase().replace(/\s+/g, '-')}`}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                            <img src={`assets/icons/${val.title.toLowerCase().replace(/\s+/g, '-')}.svg`} ></img>
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Digital Marketing</h2>
                                    <p>In digital marketing, employ strategic SEO, PPC, and SMO tactics to enhance online visibility, engage audiences, and drive business growth.</p></div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {DigitalMarketingServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <Link to={`/service-details/${val.title.toLowerCase().replace(/\s+/g, '-')}`}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                            <img src={`assets/icons/${val.title.toLowerCase().replace(/\s+/g, '-')}.svg`} ></img>
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;
