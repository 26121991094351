import React, { Component , Fragment } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';




class CounterOne extends Component{
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }
    render(){
        let Data = [
            {
                countNum : 1,
                countTitle: "Year of service",
                countBody: 'Delivering excellence for 1 years, committed to your success.',
            },
            {
                countNum : 10,
                countBody: 'Successfully completed 10+ projects, each exceeding expectations.',
                countTitle: "Projects",
            },
            // {
            //     countNum : 15,
            //     countBody: "Empowering success with 15 dedicated CCartians, our invaluable team.",
            //     countTitle: "CCartians",
            // },
            {
                countNum : 95,
                countBody: "Maintaining 95% customer retention, building lasting partnerships.",
                countTitle: "Customer Retenion",
            },
        ];

        return(
            <Fragment>
                <div className="row">
                    {Data.map( (value, index) => (
                        <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
                            <h5 className="counter">
                                <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                                    <CountUp end={this.state.didViewCountUp ? value.countNum : 0} />
                                </VisibilitySensor>
                            </h5>
                            <p className="description">{value.countTitle}</p>

                            <p className="description">{value.countBody}</p>
                        </div>
                    ))}
                </div>
            </Fragment>
        )
    }
}
export default CounterOne;