import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Website Development',
        description: 'Delivering bespoke web solutions with expertise in frontend and backend development. Elevate your online presence with our seamless services.'
    },
    {
        icon: <FiLayers />,
        title: 'Mobile App Development',
        description: 'Transforming your ideas into sleek, intuitive mobile apps. Our team excels in iOS and Android development, ensuring your app stands out in the digital marketplace.'
    },
    {
        icon: <FiUsers />,
        title: 'Graphic Designing',
        description: 'Elevate your brand with captivating visual identities. From logos to marketing materials, our graphic design services bring your vision to life with creativity and precision.'
    },
    {
        icon: <FiMonitor />,
        title: 'SEO Marketing',
        description: 'Boost your online visibility and drive organic traffic with our strategic SEO marketing services. Dominate search engine rankings and maximize your digital presence with our expertise.'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services',
        description = "Empower your digital presence with CodeCarter's comprehensive suite of services. From expert website development to cutting-edge mobile app solutions, and strategic digital marketing campaigns, we deliver excellence tailored to your needs. Elevate your brand, engage your audience, and drive success in the digital landscape with our professional expertise.";
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
