import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About',
        description = "At CodeCarter, we're a team of passionate IT professionals on a mission to empower businesses through impactful technology solutions. We bridge the gap between strategic thinking and cutting-edge development, crafting innovative websites, applications, and SEO strategies that deliver real results. Our focus goes beyond just building technology - we're dedicated to building long-term partnerships with our clients. We take the time to understand your unique business needs and challenges to exceed expectations and fuel sustainable growth.";
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are?</h3>
                                                <p>We're a passionate team of IT professionals who combine strategy and innovation to empower businesses through technology.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Why we are?</h3>
                                                <p>We believe technology can be a powerful tool for growth. We're here to help businesses leverage its potential and achieve their goals.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;